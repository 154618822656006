@import '@angular/cdk/overlay-prebuilt.css';

@font-face {
  font-family: 'GSKPrecision';
  src: url('/assets/fonts/GSKPrecision-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GSKPrecision';
  src: url('/assets/fonts/GSKPrecision-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'GSKPrecision';
  src: url('/assets/fonts/GSKPrecision-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GSKPrecision';
  src: url('/assets/fonts/GSKPrecision-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'GSKPrecision';
  src: url('/assets/fonts/GSKPrecision-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GSKPrecision';
  src: url('/assets/fonts/GSKPrecision-RegularItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'GSKPrecision';
  src: url('/assets/fonts/GSKPrecision-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GSKPrecision';
  src: url('/assets/fonts/GSKPrecision-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'BoehringerForward';
  src: url('/assets/fonts/BoehringerForwardHead.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BoehringerForward';
  src: url('/assets/fonts/BoehringerForwardHead-It.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'BoehringerForward';
  src: url('/assets/fonts/BoehringerForwardHead-Bd.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BoehringerForward';
  src: url('/assets/fonts/BoehringerForwardHead-BdIt.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'BoehringerForward';
  src: url('/assets/fonts/BoehringerForwardHead-Md.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BoehringerForward';
  src: url('/assets/fonts/BoehringerForwardHead-MdIt.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'BoehringerForward';
  src: url('/assets/fonts/BoehringerForwardText.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BoehringerForward';
  src: url('/assets/fonts/BoehringerForwardText-Bd.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BoehringerForward';
  src: url('/assets/fonts/BoehringerForwardText-BdIt.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'BoehringerForward';
  src: url('/assets/fonts/BoehringerForwardText-It.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'BoehringerForward';
  src: url('/assets/fonts/BoehringerForwardText-Md.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BoehringerForward';
  src: url('/assets/fonts/BoehringerForwardText-MdIt.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

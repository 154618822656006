ngx-extended-pdf-viewer #primaryEditorInk {
  svg {
    fill: #D9D9D9
  }
}

ngx-extended-pdf-viewer #viewerContainer {
  background-color: #525252 !important;
}

ngx-extended-pdf-viewer .page {
  border-image: none !important;
}

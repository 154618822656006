@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* You can add global styles to this file, and also import other style files */
@import 'theme';
@import 'styles/brands';
//APPLICATION SETTINGS

.scientific-platform-sections-wrapper {
  @apply md:grid-cols-4;
}

.pillar-wrapper {
  @apply xl:grid-cols-4;
}

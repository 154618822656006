.button-base {
  @apply flex
  items-center
  bg-basebg
  hover:bg-white
  active:bg-gray-600
  shadow-smbtn
  hover:shadow-smbtn-hover
  active:shadow-inner
  text-gray-700
  hover:text-brand-accent
  active:text-gray-100
  transition-all
  rounded
  hover:rounded
  active:rounded
  disabled:hover:bg-basebg
  disabled:active:bg-basebg
  disabled:hover:shadow-smbtn
  disabled:active:shadow-smbtn
  disabled:text-disabled-text
  disabled:hover:text-disabled-text
  disabled:active:text-disabled-text
  disabled:opacity-70
  disabled:saturate-0;
}

.button-base:disabled .dashboard-ring {
  @apply opacity-50;
}

.button-filter {
  @apply flex
  items-center
  text-rd
  bg-basebg
  hover:bg-white
  active:bg-gray-600
  shadow-smbtn
  hover:shadow-smbtn-hover
  active:shadow-inner
  text-gray-700
  hover:text-brand-accent
  active:text-gray-100
  transition-all
  rounded-md
  hover:rounded-md
  active:rounded-md
  aria-expanded:bg-gradient-to-bl
  aria-expanded:from-gray-600
  aria-expanded:to-gray-500
  aria-expanded:shadow-inner
  aria-expanded:text-white
  aria-expanded:aria-checked:text-brand-bright
  aria-expanded:hover:text-brand-accent
  aria-expanded:aria-checked:hover:text-brand-accent;
}

.button-reset {
  @apply box-content
  outline-none
  rounded-none
  border-none
  hover:no-underline
  focus:shadow-none
  focus:outline-none;
}

.dropdown-base {
  @apply flex
  items-center
  text-rd
  bg-basebg
  hover:bg-white
  active:bg-basebg
  shadow-smbtn
  hover:shadow-smbtn-hover
  text-gray-700
  transition-all
  rounded-md
  hover:rounded-md
  active:rounded-md
  outline-none
  focus:outline-none
  cursor-pointer
  appearance-none
}

.tag-base {
  @apply inline
  bg-basebg
  hover:bg-brand-primary/70
  active:bg-gray-600
  hover:shadow-subtle
  active:shadow-inner
  active:text-white
  border-2
  border-brand-primary/75
  active:border-gray-650
  rounded-full
  text-sm
  leading-none
  transition-all
  px-3
  py-2;
}

.subtag-base {
  @apply inline
  bg-basebg
  hover:bg-brand-secondary/70
  active:bg-gray-600
  hover:shadow-subtle
  active:shadow-inner
  active:text-white
  border-2
  border-brand-secondary/75
  active:border-gray-650
  rounded-full
  text-sm
  leading-none
  transition-all
  px-3
  py-2;
}

.button-sup {
  @apply inline-block;
}

.button-ref {
  @apply w-6
  h-4

  flex
  items-center
  justify-center

  text-xs
  font-semibold
  text-gray-600

  bg-white
  shadow-smbtn
  rounded

  hover:text-white
  hover:bg-gradient-to-tr
  hover:from-brand-primary
  hover:to-brand-secondary
  hover:shadow-smbtn-hover
  
  active:bg-gradient-to-br
  active:shadow-inner;
}

.ref-citation-tooltip {
  @apply w-full
  absolute
  z-50
  left-0
  right-0
  bottom-4
  px-4
  overflow-hidden;

  .ref-citation-tooltip-wrapper {
    @apply w-fit
    flex
    items-center
    p-0.5

    bg-gradient-to-br
    from-brand-primary
    to-brand-secondary

    shadow-subtle
    rounded-full
    overflow-hidden;

    .icon-container {
      @apply flex
      items-center
      justify-start

      w-20
      h-8

      rounded-full;

      ion-icon {
        @apply ml-1
        text-white
        text-2xl
        font-bold
        drop-shadow-sm;
      }
    }

    .ref-inline-short-citation {
      @apply flex
      items-center

      w-auto
      h-8

      text-sm
      text-gray-750

    bg-white
      rounded-full
      shadow-subtle

      px-3
      -ml-12;
    }
  }
}
